import { Button, FormLabelGroup } from '@appfolio/react-gears';
import Form from '@im-frontend/forms/Form';
import { TextField } from '@im-frontend/forms/fields';
import * as api from 'api';
import React from 'react';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

export interface Values {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  confirm_password: string;
}

interface Props {
  invitation?: api.v1.Invitation;
  onSubmit: (values: Values) => void;
}

const validatePresence = (nounPhrase: string) => (value: any) =>
  !value ? `Please enter ${nounPhrase}` : undefined;

const validateEmail = (value: any) => {
  const error = validatePresence('an email address')(value);
  if (error) {
    return error;
  }

  if (!isEmail(value)) {
    return 'Please enter a valid email address';
  }
};

const validatePassword = (value: any) => {
  if (!value) {
    return 'Please enter a password';
  }
  return null;
};

const validateConfirmPassword = (value: any, values: any) => {
  if (!value || value !== values.password) {
    return 'Passwords must match';
  }
  return null;
};

export default function SignUpForm({ invitation, onSubmit }: Props) {
  return (
    <Form<Values> onSubmit={onSubmit}>
      {() => (
        <>
          <TextField
            required
            disabled
            autoComplete="username"
            name="email"
            label="Email"
            initialValue={invitation?.email}
            validate={validateEmail}
          />
          <TextField
            required
            autoComplete="given-name"
            name="first_name"
            label="First Name"
            initialValue={invitation?.first_name}
            validate={validatePresence('your first name')}
          />
          <TextField
            required
            autoComplete="family-name"
            name="last_name"
            label="Last Name"
            initialValue={invitation?.last_name}
            validate={validatePresence('your last name')}
          />
          <TextField
            required
            autoComplete="new-password"
            name="password"
            type="password"
            label="Password"
            validate={validatePassword}
          />
          <TextField
            required
            autoComplete="new-password"
            name="confirm_password"
            type="password"
            label="Confirm Password"
            validate={validateConfirmPassword}
          />
          <FormLabelGroup>
            <Button
              className="my-2 py-3 w-100"
              color="primary"
              type="submit"
              disabled={!invitation}
            >
              Create Account
            </Button>
            Already have an account? <Link to="/session/new">Sign In</Link>
          </FormLabelGroup>
        </>
      )}
    </Form>
  );
}
