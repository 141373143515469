import { maybeReportException } from '@im-frontend/utils/errors';
import * as api from 'api';
import PageContent from 'components/shared/PageContent';
import Placard from 'components/shared/Placard';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as errors from 'utils/errors';
import SignUpForm, { Values } from './SignUpForm';

interface Match {
  id: string;
}

/**
 * Your everyday login screen.
 */
function Show({
  history,
  match: {
    params: { id },
  },
}: RouteComponentProps<Match>) {
  const [invitation, setInvitation] = useState<api.v1.Invitation | undefined>();
  const [fatalError, setFatalError] = useState<string | undefined>();

  useEffect(() => {
    if (!invitation) {
      api.v1.invitations
        .show(id)
        .then(invitation => {
          if (invitation.state === 'redeemed') {
            history.push('/session/new', {
              alert: 'invitationAlreadyRedeemed',
              formHints: { email: invitation.email },
            });
          } else {
            setInvitation(invitation);
          }
        })
        .catch(() => {
          setFatalError('Sorry, something went wrong.');
        });
    }
  }, [history, id, invitation]);

  const signUp = async (values: Values) => {
    if (!invitation) {
      return;
    }
    const request = {
      invitation: { id },
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
    };

    try {
      await api.v1.principals.create(request);
      history.push('/oauth2/continue');
    } catch (err) {
      if (!api.v1.isErrors(err.response?.data)) {
        setFatalError('Sorry, something went wrong.');
        return;
      }
      if (errors.has(err.response.data, 'duplicate_key', 'email')) {
        history.push('/session/new', {
          alert: 'emailDuplicateKey',
          formHints: { email: values.email },
        });
      }
      maybeReportException(err);
      return errors.mapToForm(err.response.data);
    }
  };

  if (!invitation) {
    return null;
  }

  if (invitation.state === 'expired') {
    return (
      <Placard>
        This invitation is no longer valid. Please contact your System Admin for
        a new invitation to AppFolio Investment Manager.
      </Placard>
    );
  }

  return (
    <PageContent size="sm" title="Create Your Account">
      {fatalError ? (
        <Placard>{fatalError}</Placard>
      ) : (
        <SignUpForm invitation={invitation} onSubmit={signUp} />
      )}
    </PageContent>
  );
}

export default Show;
