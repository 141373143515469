const root = document.getElementById('root');
const dataset = root?.dataset ?? {};

export type Environment = 'development' | 'ci' | 'qa' | 'production';

/// Deployment environment.
export const environment: Environment = dataset.environment as Environment;

/// SCM release SHA.
export const release = dataset.release;
