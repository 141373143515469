import { Error, Errors } from 'api/v1';
import { FORM_ERROR } from 'final-form';

/**
 * Map an api.v1.Errors to react-final-form feedback messages.
 * Server errors may be about a specific field or about the whole form.
 * If there are multiple errors for some (or no) field, then only the
 * last error message is retained.
 */
export const mapToForm = (body: Errors) =>
  body.errors.reduce((formErrors: any, serverError: Error) => {
    const { field, message } = serverError;
    if (field) {
      formErrors[field] = message;
    } else {
      formErrors[FORM_ERROR] = message;
    }
    return formErrors;
  }, {});

/**
 * Return true if the api.v1.Errors contains at least one of the
 * designated type (and optionally, designated field).
 */
export const has = (body: Errors, type: string, field?: string) =>
  !!body.errors.find(
    (e: any) => e.type === type && (!field || e.field === field)
  );
