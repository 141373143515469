import { AxiosError } from 'axios';
import * as wwwForm from '../../encoding/www-form';
import client from '../client';
import { Error, Grant, UserInfo } from '../media';

/// An OAuth 2.0 password grant request (minus client ID, which is added
/// to the payload automatically).
interface PasswordRequest {
  grant_type: 'password';
  username: string;
  password: string;
  scope?: string;
  continuation_uri?: string;
}

// @see backend/global/lib/tasks/db_helper.rb
const CLIENT_ID = 'im:frontend:login';

const withClientID = (payload: Record<string, any>) =>
  Object.assign({ client_id: CLIENT_ID }, payload);

const unauthorizedAsNull = (err: AxiosError): null => {
  if (err.response?.status === 401) {
    return null;
  }
  throw err;
};

const badRequestAsData = (err: AxiosError) => {
  if (err.response?.status === 400) {
    return err.response.data as Error;
  }
  throw err;
};

export const token: (payload: PasswordRequest) => Promise<Grant | Error> = (
  payload: PasswordRequest
) =>
  client
    .post<Grant | Error>(
      '/oauth2/token',
      wwwForm.urlencode(withClientID(payload)),
      {
        headers: { 'Content-Type': wwwForm.CONTENT_TYPE },
      }
    )
    .then(resp => resp.data)
    .catch(badRequestAsData);

export const userInfo = () =>
  client
    .get<UserInfo | null>('/oauth2/userinfo')
    .then(resp => resp.data)
    .catch(unauthorizedAsNull);

export const revoke = (token?: string) =>
  client
    .post('/oauth2/revoke', wwwForm.urlencode(withClientID({ token })), {
      headers: { 'Content-Type': wwwForm.CONTENT_TYPE },
    })
    .then(resp => resp.data);
