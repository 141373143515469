import { ListGroup, ListGroupItem } from '@appfolio/react-gears';
import { stringifyFields } from '@im-frontend/praxis-api/qs';
import * as api from 'api';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const fields = stringifyFields({
  id: 1,
  firstName: 1,
  lastName: 1,
  accounts: {
    id: 1,
    name: 1,
    serviceUri: 1,
  },
});

function PrincipalShowPage({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) {
  const [principal, setPrincipal] = useState<api.v1.Principal>();

  useEffect(() => {
    if (!principal) {
      api.v1.principals.show(id, { fields }).then(principal => {
        setPrincipal(principal);
      });
    }
  }, [principal, id]);

  const base = '/oauth2/continue';

  return (
    <>
      <h3>choose an account</h3>
      <ListGroup>
        {principal &&
          principal?.accounts?.map((account: any) => {
            return (
              <ListGroupItem
                key={account.id}
                style={{ marginBottom: '10px', border: '1px solid #ccc' }}
              >
                <a href={`${base}?account_hint=${account.id}`}>
                  <b>{account.name}</b>
                </a>
              </ListGroupItem>
            );
          })}
      </ListGroup>
    </>
  );
}

export default PrincipalShowPage;
