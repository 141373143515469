import Sparse from 'components/layouts/Sparse';
import Splash from 'components/layouts/Splash';
import NotFound from 'components/shared/NotFound';
import Dashboard from 'components/views/Dashboard';
import Invitations from 'components/views/Invitations';
import Password from 'components/views/Password';
import Accounts from 'components/views/Principals/Show';
import Session from 'components/views/Session';
import ZenHall from 'components/views/ZenHall';
import React from 'react';
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';

import './App.scss';

/// Reduce mental burden by letting us navigate to back end routes via React router.
function ExternalRedirect({
  path,
  location,
}: {
  path: string;
  location?: RouteComponentProps['location'];
}) {
  return (
    <Route
      exact
      path={path}
      render={() => {
        window.location.replace(path + (location?.search || ''));
        return null;
      }}
    />
  );
}

/// Generic 404 screen.
function RouteNotFound() {
  return (
    <Sparse>
      <NotFound />
    </Sparse>
  );
}

/**
 * The whole app + the one-and-only central router.
 *
 * Note that the `Splash` layout wraps every route. An alternative approach
 * would be to let each route define its own page-layout and only do routing
 * in the app. (There is a `Sparse` layout available which does not have the
 * IM logo, for instance.)
 *
 * If we ever need different layouts for different routes, please remove the
 * top-level layout here and delegate layout decision to routes instead.
 */
function App() {
  return (
    <div id="app">
      <BrowserRouter basename="/">
        <Splash>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/appf" component={ZenHall} />
            <Route path="/invitations" component={Invitations} />
            <Route path="/session" component={Session} />
            <Route path="/password" component={Password} />
            <Route path="/principals/:id" component={Accounts} />
            <ExternalRedirect path="/auth/okta" />
            <ExternalRedirect path="/oauth2/continue" />
            <Route component={RouteNotFound} />
          </Switch>
        </Splash>
      </BrowserRouter>
    </div>
  );
}

export default App;
