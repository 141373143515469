import { Card } from '@appfolio/react-gears';
import React from 'react';

/**
 * A Card with center-aligned text and some padding.
 */
function Placard({ children }: { children: React.ReactNode }) {
  return <Card className="text-center p-4">{children}</Card>;
}

export default Placard;
