import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  title?: string;
}

const SIZES = {
  sm: '4in',
  md: '7.5in',
  lg: '10in',
};

/**
 * Actual page content that lives inside a layout. Draws a header with the
 * page title and limits content width with obnoxiously wide viewports.
 */
function PageContent({ children, className, size = 'md', title }: Props) {
  return (
    <div className={className} style={{ maxWidth: SIZES[size] }}>
      {title && <h1>{title}</h1>}
      {children}
    </div>
  );
}

export default PageContent;
