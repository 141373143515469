export interface Account {
  id: string;
  name: string;
  product: 'im';
  service_uri: string;
}

export interface Invitation {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  state: 'pending' | 'redeemed' | 'expired';
}

export interface Error {
  field?: string;
  message: string;
  type?: string;
}

export interface Errors {
  errors: Error[];
}

export interface Principal {
  id: string;
  firstName: string;
  lastName: string;
  accounts: Account[];
}

export const isErrors = (v: any): v is Errors =>
  v &&
  typeof v === 'object' &&
  v.errors &&
  v.errors.every &&
  v.errors.every((e: any) => e.message);
