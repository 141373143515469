import { Button } from '@appfolio/react-gears';
import * as api from 'api';
import Placard from 'components/shared/Placard';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type SessionInfo = api.v0.UserInfo | null | undefined;

/**
 * Quasi-useless page that we serve up as the default route, just so there's something to see.
 */
function Dashboard({ history }: RouteComponentProps) {
  const [userInfo, setUserInfo] = useState<SessionInfo>();

  useEffect(() => {
    if (userInfo === undefined) {
      api.v0.oauth2.userInfo().then(setUserInfo);
    }
  }, [userInfo]);

  let message: string = 'Checking session status...';
  let action: React.ReactNode;

  const signIn = () => history.push('/session/new');
  const signOut = () =>
    api.v0.oauth2.revoke().then(() => history.push('/session/new'));

  if (userInfo) {
    message = `You are signed in as ${userInfo.first_name} ${userInfo.last_name},
               but we don't know where to send you. Please visit your IM account
               link directly: app.appfolioim.com/your-account-name-here`;
    action = (
      <Button color="primary" onClick={signOut}>
        Sign Out
      </Button>
    );
  } else if (userInfo === null) {
    message = 'You are not signed in.';
    action = (
      <Button color="primary" onClick={signIn}>
        Sign In
      </Button>
    );
  }

  return (
    <Placard>
      <p>{message}</p>
      <div>
        {action}
        <Button color="link" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
    </Placard>
  );
}

export default Dashboard;
