import { Button } from '@appfolio/react-gears';
import Form from '@im-frontend/forms/Form';
import { TextField } from '@im-frontend/forms/fields';
import { maybeReportException } from '@im-frontend/utils/errors';
import * as api from 'api';
import PageContent from 'components/shared/PageContent';
import jwtDecode from 'jwt-decode';
import { parse as qsParse } from 'qs';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as errors from 'utils/errors';

interface Values {
  password: string;
  confirmedPassword: string;
}

const validatePassword = (value: any) => {
  if (!value) {
    return 'Please enter a password';
  }
  return null;
};

const validateConfirmPassword = (value: any, values: any) => {
  if (!value || value !== values.password) {
    return 'Passwords must match';
  }
  return null;
};

function ResetPassword({ location, history }: RouteComponentProps) {
  const [submitting, setSubmitting] = useState(false);

  const resetPassword = async (values: Values) => {
    setSubmitting(true);
    const { code } = qsParse(location.search, { ignoreQueryPrefix: true });
    try {
      await api.v1.principals.resetPassword({
        password: values.password,
        code: `${code}`,
      });
      // HACK: parsing a JWT, which should be opaque to us, to learn the email address for login.
      // TODO: make resetPassword return this detail as part of its response payload
      const claims = jwtDecode<Record<string, any>>(`${code}`);
      const outcome = await api.v0.oauth2.token({
        grant_type: 'password',
        username: claims['appf.io#unm'],
        password: values.password,
      });

      if (api.v0.isGrant(outcome)) {
        history.push('/oauth2/continue');
      }
    } catch (err) {
      if (api.v1.isErrors(err?.response?.data)) {
        setSubmitting(false);
        return errors.mapToForm(err.response.data);
      } else {
        history.replace('/session/new', { alert: 'passwordResetFailed' });
      }
      setSubmitting(false);
      maybeReportException(err);
    }
  };

  return (
    <PageContent size="sm" title="Reset Password">
      <Form<Values> onSubmit={resetPassword}>
        {() => (
          <>
            <TextField
              required
              autoComplete="new-password"
              name="password"
              type="password"
              label="New Password"
              validate={validatePassword}
            />
            <TextField
              required
              autoComplete="new-password"
              name="confirm_password"
              type="password"
              label="Confirm Password"
              validate={validateConfirmPassword}
            />
            <Button
              className="my-2 py-3 w-100"
              color="primary"
              type="submit"
              disabled={submitting}
            >
              Reset Password
            </Button>
          </>
        )}
      </Form>
    </PageContent>
  );
}

export default withRouter(ResetPassword);
