export const CONTENT_TYPE = 'application/x-www-form-urlencoded';

export function urlencode<T>(body: T) {
  const fd = new FormData();
  Object.entries(body).forEach(([k, v]) => v && fd.set(k, v));

  let s = '';
  function encode(s: string) {
    return encodeURIComponent(s).replace(/%20/g, '+');
  }

  for (const pair of fd.entries()) {
    if (typeof pair[1] == 'string') {
      s += (s ? '&' : '') + encode(pair[0]) + '=' + encode(pair[1]);
    }
  }
  return s;
}
