import Waiting from '@appfolio/react-gears/lib/components/Waiting';
import React from 'react';

const waitingStyles: React.CSSProperties = {
  margin: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  msTransform: 'translate(-50%, -50%)',
  transform: 'translate(-50%, -50%)',
};

export const WAITING_CLASS_NAME = 'waiting-modal';

interface Props {
  isOpen: boolean;
  backdrop: boolean;
  title?: string;
}

const CenteredWaiting: React.FunctionComponent<Props> = ({
  isOpen,
  backdrop,
  title,
}: Props) => {
  return (
    <Waiting
      style={waitingStyles}
      isOpen={isOpen}
      backdrop={backdrop}
      title={title}
      className={WAITING_CLASS_NAME}
    />
  );
};

export default CenteredWaiting;
