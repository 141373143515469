import NotFound from 'components/shared/NotFound';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import Show from './Show';

function Session({ location }: RouteComponentProps) {
  return (
    <Switch location={location}>
      <Route path="/invitations/:id" component={Show} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Session;
