import React from 'react';
import Placard from './Placard';

/**
 * A placard with helpful, UX-approved "page not found" message.
 */
function NotFound() {
  return (
    <Placard>
      <p>
        Sorry, the page you are looking for was not found, check for any typos
        and try again.
      </p>
      <pre>Reference ID: 404 Not Found</pre>
      <pre>Unknown Path: {window.location.pathname}</pre>
    </Placard>
  );
}

export default NotFound;
