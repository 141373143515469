import NotFound from 'components/shared/NotFound';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import New from './New';

function Session({ location }: RouteComponentProps) {
  return (
    <Switch location={location}>
      <Route path="/session/new" component={New} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Session;
