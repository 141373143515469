import { parse as qsParse } from 'qs';
import { RouteComponentProps } from 'react-router-dom';

type Location = RouteComponentProps['location'];

/**
 * Pre-fill email fields with something useful if search or router state provides
 * a clue.
 */
export function guessEmail(location: Location) {
  const fromState = location.state?.formHints?.email;
  const { email: fromSearch } = qsParse(location.search, {
    ignoreQueryPrefix: true,
  });
  return fromState || fromSearch || undefined;
}
