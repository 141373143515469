import { Col, Container, Row } from '@appfolio/react-gears';
import classnames from 'classnames';
import React from 'react';

/**
 * Blank white page; content centered vertically and horizontally on page.
 */
function Sparse({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Container
      className={classnames('main__content', 'p-5', 'vh-100', className)}
      fluid
    >
      <Row className="h-100">
        <Col className="col-12 d-flex">
          <div className="m-auto">{children}</div>
        </Col>
      </Row>
    </Container>
  );
}

export default Sparse;
