export interface Error {
  error: string;
  error_description: string;
}

export interface Grant {
  access_token: string;
  expires_in: number;
  token_type: 'bearer';
}

export interface UserInfo {
  id: string;
  first_name: string;
  last_name: string;
  internal_user: boolean;
  usernames: string[];
  invitations: string[];
}

export const isGrant = (x: any): x is Grant =>
  x && ['access_token', 'expires_in', 'token_type'].every(k => x[k]);
