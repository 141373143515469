import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import ForgotPassword from './Forgot';
import ResetPassword from './Reset';

function Password({ location }: RouteComponentProps) {
  return (
    <Switch location={location}>
      <Route path="/password/forgot" component={ForgotPassword} />
      <Route path="/password/reset" component={ResetPassword} />
    </Switch>
  );
}

export default Password;
