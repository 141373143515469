import { Button } from '@appfolio/react-gears';
import Waiting from '@im-frontend/components/Waiting';
import * as api from 'api';
import PageContent from 'components/shared/PageContent';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import VisitAccount from './VisitAccount';

type SessionInfo = api.v0.UserInfo | null | undefined;

export function dayPeriod() {
  const hr = new Date().getHours();

  switch (true) {
    case hr > 18 || hr < 6:
      return 'Night';
    case hr < 12:
      return 'Morning';
    case hr < 17:
      return 'Afternoon';
    default:
      return 'Evening';
  }
}

/**
 * Internal admin page for AppFolio employees.
 */
function ZenHall({ history }: RouteComponentProps) {
  const [userInfo, setUserInfo] = useState<SessionInfo>();
  const isLoading = userInfo === undefined;
  useEffect(() => {
    if (isLoading) {
      api.v0.oauth2.userInfo().then(setUserInfo);
    }
  }, [isLoading, userInfo]);
  const unauthorized = !isLoading && !userInfo?.internal_user;

  const signOut = () =>
    api.v0.oauth2.revoke().then(() => history.push('/session/new'));

  if (!isLoading && unauthorized) {
    return <Redirect to="/" />;
  }

  return (
    <PageContent
      size="lg"
      title={`Good ${dayPeriod()}, ${userInfo?.first_name}!`}
    >
      <Waiting backdrop isOpen={isLoading || unauthorized} />
      <p>
        To visit an account, type part of the account name below and then select
        the matching search result.
      </p>
      <VisitAccount />
      <p>To sign in as someone else, click the button below.</p>
      <Button color="primary" onClick={signOut}>
        Sign Out
      </Button>
    </PageContent>
  );
}

export default ZenHall;
