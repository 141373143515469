import { Button } from '@appfolio/react-gears';
import Form from '@im-frontend/forms/Form';
import { TextField } from '@im-frontend/forms/fields';
import * as api from 'api';
import PageContent from 'components/shared/PageContent';
import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as formHints from 'utils/formHints';
import isEmail from 'validator/lib/isEmail';

interface Values {
  email: string;
}

const validateEmail = (email: any) => {
  if (!email) {
    return 'Please enter an email address';
  }
  if (!isEmail(email)) {
    return 'Please enter a valid email address';
  }
};

function Forgot({ location, history }: RouteComponentProps) {
  const [submitting, setSubmitting] = useState(false);
  const resetPassword = async (values: Values) => {
    setSubmitting(true);
    await api.v1.principals.requestResetPassword(values);

    history.push('/session/new', { passwordResetSent: true });
  };

  return (
    <PageContent size="sm" title="Forgot Password">
      <p>
        Please enter your email address and we&apos;ll send you a link to reset
        your password.
      </p>
      <Form<Values> onSubmit={resetPassword}>
        {() => (
          <>
            <TextField
              required
              name="email"
              label="Email"
              initialValue={formHints.guessEmail(location)}
              validate={validateEmail}
            />
            <Button
              className="my-2 py-3 w-100"
              color="primary"
              type="submit"
              disabled={submitting}
            >
              Send Email
            </Button>
            <Link to="/session/new">Cancel</Link>
          </>
        )}
      </Form>
    </PageContent>
  );
}

export default Forgot;
