import { stringify } from 'qs';
import client from '../client';
import { Principal } from '../media';

interface CreateRequest {
  invitation: { id: string };
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

export const show = (id: string, { fields }: { fields: string }) =>
  client
    .get<Principal>(`/principals/${id}?${stringify({ fields: fields })}`)
    .then(resp => resp.data);

export const create = (payload: CreateRequest) =>
  client
    .post<undefined>('/principals/actions/create', payload)
    .then(resp => resp.status);

interface RequestResetPasswordRequest {
  email: string;
}

export const requestResetPassword = (payload: RequestResetPasswordRequest) =>
  client
    .post<undefined>('/principals/actions/request_reset_password', payload)
    .then(resp => resp.status);

interface ResetPasswordRequest {
  code: string;
  password: string;
}

export const resetPassword = (payload: ResetPasswordRequest) =>
  client
    .put<undefined>('/principals/actions/reset_password', payload)
    .then(resp => resp.status);
