import { Col, Container, Row } from '@appfolio/react-gears';
import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

const logoStyle = { background: '#335072' };

/**
 * Splash screen with IM logo occupying lefthand 1/3rd of the page; content centered
 * vertically and horizontally in right 2/3rds of the page.
 */
function Splash({ children }: { children: React.ReactNode }) {
  // Superfluous stylistic flair because Tony is a jerk
  const { pathname } = useLocation();
  const zen = pathname.startsWith('/appf') ? 'zen' : undefined;

  return (
    <Container className="vh-100" fluid>
      <Row className="h-100">
        <Col className="col-12 col-lg-4 d-flex" style={logoStyle}>
          <img
            alt="Product Logo"
            className="d-block m-auto w-50"
            src="https://af-im-ui.s3.us-east-2.amazonaws.com/static-assets/logos/appfolio-investment-manager-logo-white-stacked-2023.svg"
          />
        </Col>
        <Col
          className={classnames(
            'main__content',
            'col-12',
            'col-lg-8',
            'd-flex',
            'my-2',
            zen
          )}
        >
          <div className="m-auto">{children}</div>
        </Col>
      </Row>
    </Container>
  );
}

export default Splash;
